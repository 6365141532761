
import { IUserCustomer } from '@/modules/session/types';
import $ph from '@/plugins/phoenix'
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DashboardWidgetCustomer extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop() readonly settings: any;

  showCustomerSwitch = false;

  get userName() {
    return session.user.name
  }

  get userBaseCurr() {
    return session.user.userBaseCurrency
  }

  get totalAmount() {
    return $ph.format(wallet.total, { dec: wallet.baseCurrency.currencyPrecision })
  }

  get showOnlyCustomerName() {
    return this.userName.toUpperCase() === this.customerName.toUpperCase()
  }

  get customerName() {
    return wallet.customer ? wallet.customer.customerLegalName : ''
  }

  get customerNumber() {
    return wallet.customer ? wallet.customer.customerNumberPublic : ''
  }

  get isSelf() {
    return wallet.customer ? wallet.customer.customerType !== 'PRV' : true
  }

  get showCustomerCol(): boolean {
    return this.settings?.showCustomer
  }

  get showBalanceCol(): boolean {
    return this.settings?.balance
  }

  get switchCustomer(): boolean {
    return this.settings?.switchCustomer && this.linkedCustomers.length > 0
  }

  get flat(): boolean {
    return this.settings?.flat
  }

  get vertical(): string {
    return this.settings?.vertical || 'center'
  }

  get align(): string {
    return this.settings?.align || 'left'
  }

  get welcome(): boolean {
    return this.settings?.welcome
  }

  get dense(): boolean {
    return this.settings?.dense
  }

  get rowAlignContent(): string {
    if (this.showBalanceCol && this.showCustomerCol && this.vertical !== 'center') {
      return 'space-between'
    } else {
      return this.vertical
    }
  }

  get rowStyle(): string {
    if (this.dense) {
      return 'height: 98px;'
    } else {
      return 'height: 228px;'
    }
  }

  get customerCols(): number {
    if (this.showBalanceCol) {
      if (this.vertical === 'center') {
        return 6
      } else {
        return 12
      }
    } else {
      return 12
    }
  }

  get customerClass(): string {
    let cls = 'text-' + this.align + ' '
    cls += 'pa-0 '
    return cls
  }

  get customerOrder(): number {
    if (this.vertical === 'end' || (this.vertical === 'center' && this.align === 'right')) {
      return 2
    } else {
      return 1
    }
  }

  get balanceCols(): number {
    if (this.showCustomerCol) {
      if (this.vertical === 'center') {
        return 6
      } else {
        return 12
      }
    } else {
      return 12
    }
  }

  get balanceClass(): string {
    let cls = 'text-' + (this.align === 'right' ? 'left' : (this.align === 'center' ? 'center' : 'right')) + ' '
    cls += 'pa-0 '
    return cls
  }

  get balanceOrder(): number {
    if (this.vertical === 'end' || (this.vertical === 'center' && this.align === 'right')) {
      return 1
    } else {
      return 2
    }
  }

  get linkedCustomers(): Array<IUserCustomer> {
    return wallet.linkedCustomers
  }

  openCustomersList() {
    this.showCustomerSwitch = true
  }

  async switchToCustomer(customer: IUserCustomer) {
    this.showCustomerSwitch = false
    if (customer != null) {
      try {
        await session.userSwitchCustomer(customer.customerNumber)
      } catch (err) {
        session.pushError(err)
      }
    }
  }
}

