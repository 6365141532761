
import $ph from '@/plugins/phoenix'
import { session, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DashboardWidgetBalance extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop() readonly settings: any;

  get userBaseCurr() {
    return session.user.userBaseCurrency
  }

  get totalAmount() {
    return $ph.format(wallet.total, { dec: wallet.baseCurrency.currencyPrecision })
  }

  get flat(): boolean {
    return this.settings?.flat
  }

  get vertical(): string {
    return this.settings?.vertical || 'center'
  }

  get align(): string {
    return this.settings?.align || 'left'
  }

  get dense(): boolean {
    return this.settings?.dense
  }

  get rowAlignContent(): string {
    return this.vertical
  }

  get rowStyle(): string {
    if (this.dense) {
      return 'height: 98px;'
    } else {
      return 'height: 228px;'
    }
  }

  get balanceCols(): number {
    return 12
  }

  get balanceClass(): string {
    let cls = 'text-' + this.align + ' '
    cls += 'pa-0 '
    return cls
  }

  get balanceOrder(): number {
    return 1
  }

  get cardStyle() {
    return 'background: ' + this.settings?.background + '; '
  }
}

