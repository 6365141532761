
import { EntityStates, IAccountWidgetSettings } from '@/modules/system/types';
import $ph from '@/plugins/phoenix'
import { system, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ACCOUNT_DEFAULT } from '../store';
import { CurrencyType, ICurrency, IDashboardItem } from '../types';

export enum AppendModes {
  None = 'none',
  SingleCrypto = 'single-crypto', // Only one account in this group in crypto currency
  SingleFiat = 'single-fiat', // Only one account in this group in fiat currency
  SingleAny = 'single-any', // Only one account in this group in any currency
}

interface IAccountGroup {
  name: string,
  types: Array<string>,
  items: Array<IDashboardItem>
  appendMode: AppendModes
  appendType: string
  appendProduct: string
  appendable: Array<IDashboardItem>
}

@Component
export default class DashboardWidgetAccounts extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop() readonly settings: any;
  @Prop() readonly favorite: any;

  filter = ''
  hideZeroAccounts = false

  get accounts() {
    return wallet.dashboard.filter(acc => this.getItemSettings(acc).style !== 'none' && (acc.dashboardItemFavorite || !this.isFavoriteOnly) &&
      (!this.hideZeroAccounts || (acc.account && acc.account.accountBalAvail !== 0)) &&
      (this.filter === null || acc.currency.currencyCode.toUpperCase().includes(this.filter.toUpperCase()) || (acc.account && acc.account.accountName.toUpperCase().includes(this.filter.toUpperCase()))))
  }

  get isFavoriteOnly(): boolean {
    return this.favorite === '' || this.favorite === 'true' || this.favorite === true
  }

  get accountsSettings(): Record<string, IAccountWidgetSettings> {
    return system.accountSettings
  }

  get currencies(): Array<ICurrency> {
    return wallet.currencies.filter(item => item.currencyState === EntityStates.Active).sort((a, b) => a.currencyOrder - b.currencyOrder);
  }

  get groups() {
    const groups: Array<IAccountGroup> = []
    const index: Record<string, IAccountGroup> = {}

    if (this.settings && this.settings.groups) {
      this.settings.groups.forEach(grp => {
        const accgrp: IAccountGroup = {
          name: grp.name,
          types: grp.types,
          appendMode: grp.appendMode || AppendModes.None,
          appendType: grp.appendType,
          appendProduct: grp.appendProduct,
          items: [],
          appendable: [],
        }
        accgrp.types.forEach(t => { index[t] = accgrp })
        groups.push(accgrp)
      })
    }

    this.accounts.forEach(acc => {
      if (acc.account) {
        const grp = index[acc.account.accountTypeCode]
        if (grp) {
          grp.items.push(acc)
        }
      }
    })

    groups.forEach(grp => {
      grp.items.sort((a, b) => {
        if (a.currency.currencyOrder === b.currency.currencyOrder) {
          const aOrder = this.getItemSettings(a).order || 99999
          const bOrder = this.getItemSettings(b).order || 9999
          if (aOrder === bOrder) {
            const aname = (a.dashboardItemName === '' ? null : a.dashboardItemName) ||
              (a.accountName === '' ? null : a.accountName) ||
              ''
            const bname = (a.dashboardItemName === '' ? null : a.dashboardItemName) ||
              (a.accountName === '' ? null : a.accountName) ||
              ''
            return aname.localeCompare(bname)
          } else {
            return aOrder - bOrder
          }
        } else {
          return a.currency.currencyOrder - b.currency.currencyOrder
        }
      })

      if (!this.isFavoriteOnly && grp.appendMode !== AppendModes.None) {
        this.currencies.forEach(curr => {
          if ((grp.appendMode === AppendModes.SingleAny ||
            (grp.appendMode === AppendModes.SingleCrypto && curr.currencyType !== CurrencyType.Fiat) ||
            (grp.appendMode === AppendModes.SingleFiat && curr.currencyType === CurrencyType.Fiat)) &&
            !grp.items.some(item => item.account && item.account.accountCurrency === curr.currencyCode)) {
            const item: IDashboardItem = $ph.clone(wallet.dashboard.find(item => item.currencyCode === curr.currencyCode))
            if (item) {
              item.account = $ph.clone(ACCOUNT_DEFAULT)
              item.account!.currency = curr
              item.account!.accountTypeCode = grp.appendType
              item.account!.productCode = grp.appendProduct
              item.account!.accountCurrency = curr.currencyCode
              grp.items.push(item)
            }
          }
        })
      }
    })

    return groups.filter(grp => grp.items.length > 0);
  }

  getItemSettings(item: IDashboardItem): IAccountWidgetSettings {
    let settings: IAccountWidgetSettings | null = null

    if (item.account) {
      settings = this.accountsSettings[item.account.accountTypeCode + '/' + item.account.productCode + '/' + item.account.accountCurrency]

      if (!settings) {
        settings = this.accountsSettings[item.account.accountTypeCode + '/*/' + item.account.accountCurrency]
      }

      if (!settings) {
        settings = this.accountsSettings[item.account.accountTypeCode + '/' + item.account.productCode + '/*']
      }

      if (!settings) {
        settings = this.accountsSettings[item.account.accountTypeCode + '/*/*']
      }
    }

    return settings || {
      order: 9999,
      action: 'none',
      operations: [],
      graph: 'none',
      baseAmount: false,
      holdAmount: false,
      accountNumber: false,
      accountName: false,
      tradingInfo: false,
      typeIcon: '',
      iconColor: 'primary',
      backColor: 'card',
      background: '',
      style: 'none',
    }
  }
}

