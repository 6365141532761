
import $ph from '@/plugins/phoenix'
import { system, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOperationFull } from '../types';

@Component
export default class DashboardWidgetLastTrans extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop() readonly settings: any;

  openPreview(operation: IOperationFull) {
    wallet.setCurrentPreview({ visible: true, operation })
  }

  get operTypes(): Record<string, string> {
    return system.operStyles
  }

  get transactions() {
    return wallet.lastTransactions
  }
}

