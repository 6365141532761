
import settings from '@/plugins/settings';
import { session, system, wallet } from '@/plugins/store';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DiscretizationTypes } from '../types';

@Component
export default class DashboardGraphPortfolio extends Vue {
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop() readonly settings: any;

  refreshing = false
  discret = 1

  get balances() {
    return wallet.dashboardBalances || null
  }

  get gradient() {
    return settings.company.companyUISettings.graphs.error.split(',')
  }

  get lineWidth() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 1
    } else if (this.$vuetify.breakpoint.lgAndDown) {
      return 2
    } else {
      return 3
    }
  }

  get accounts() {
    const opers: Array<string> = system.balanceAccTypes
    return wallet.accounts.filter(a =>
      a.accountBalAvailEq > 0 &&
      opers.some(op => (op === (a.accountTypeCode + '/' + a.productCode)) || (op === (a.accountTypeCode + '/*'))))
      .sort((a, b) => b.accountBalAvailEq - a.accountBalAvailEq)
  }

  get total() {
    return wallet.total
  }

  async changeDiscret(discret: DiscretizationTypes) {
    this.refreshing = true;
    try {
      wallet.setDashboardDiscret(discret)
      await wallet.loadBaseData(session.user)
    } catch (err) {
      session.pushError(err)
    }
    this.refreshing = false;
  }

  get flat(): boolean {
    return this.settings?.flat
  }
}

